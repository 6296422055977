.setup {
  width: 100%;
  padding: 10px;
  height: calc(100vh - 50px);
  position: relative;
  top: 50px;

  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__text {
      margin-bottom: 40px;

    
    }
  }
  .setup__body__form__description {
    margin: 30px 0;
    text-align: center;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    max-width: 400px;
    font-size: .78571429rem;
  }

  .setup__body__form__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .ui.dropdown, .ui.input {
      width: 200px;
      margin-left: 20px;
    }
  }
  .setup__body__form__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 20px;

  }
}