.calculator-field {
  margin-bottom: 10px;

  label {
    display: block;
    margin-bottom: 5px;
  }
  .ui.input.error>input {
    background-color: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    box-shadow: none; 
  }
}