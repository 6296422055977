.step-progress {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  &__step {
    text-align: center;
    position: relative;

    width: 100%;
    &__progress {
      display: flex;
      position: relative;
      justify-content: space-around;

      &__line {
        position: absolute;
        z-index: -1;
        top: calc(50% - 3px);
        background-color: #00b5ad;
        height: 6px;
        width: 99%;
        &.done, &.active {
          background-color: #00b5ad;
        }
        &.todo {
          opacity: 0.2;
        }
      }
      .icon {
        background-color: white;
        &.todo::before {
          opacity: 0.2;
        }
      }
    }
  }
}