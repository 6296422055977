.results {
  
  &__inner {
    padding: 20px;
    box-sizing: border-box;
    margin-left: 0;
    top: 50px;
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
    overflow: auto;
    
    @media only screen and (min-width: 768px) {
      margin-left: 175px;
      width: calc(100% - 175px);
    }

    &__stacked {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
    }
  }
}