.stacked-bar-chart {
  display: flex;
  width: 100%;
  min-width: 300px;
  max-width: 500px;
  height: 500px;
  margin-bottom: 40px;
  flex-wrap: wrap;

  &__chart {
    width:100%
  }
  &__legend {
    list-style: none;
    font-size: 0.8em;
    overflow: auto;
      li {
        margin-bottom: 5px;
        span {
          vertical-align: sub;
          margin-right: 5px;
          width: 20px;
          height: 15px;
          display: inline-block;
        }
      }
  }
}