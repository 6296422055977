.form-section  {
  width: 100%;
  max-width: 1000px;
  background-color: white;
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  .ui.accordion .title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }

  &.no-border {
    border: none;
    padding: 0;
  }
}