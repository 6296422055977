.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100vh;
  padding-top: 90px;

  p {
    margin-bottom: 40px;
  }

  &__language {
    position: fixed;
    top: 17px;
    right: 20px;
    z-index: 10;
  }
  &__actions {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 60px 0 40px 0;

    button.ui.button{
      margin-right: 40px;
    }
  }

  &__support {
    &__list {
      margin: 20px 0;
      &__item {
        font-size: 1.2em;
        margin-bottom: 10px;

        &__name {
          margin: 40px 0 10px 0;
          font-weight: bold;
        }

        &__logo {
          margin: 0 0 20px 0;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          img {
            height: 70px;
            margin: 10px 20px;
          }
        }
      }
    }
  }

  &__info-link{
    color: #4183c4;
    text-decoration: none;
    cursor: pointer;
  }

  &__info-modal{
    white-space: pre-line;
    .content {
      height: 80vh;

      h3 {
        margin-top: 60px;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  &__footer {
    padding-bottom: 60px;
  }
}