.sensitivity-analysis {
  box-sizing: border-box;
  max-width: 1000px;
  margin-bottom: 40px;

  &__controls {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .ui.buttons>.ui.dropdown:last-child .menu, .ui.menu .right.dropdown.item .menu, .ui.menu .right.menu .dropdown:last-child .menu {
      left: 0;
    }

    &__target {
      min-width: 300px;
      max-width: 50%;

      .ui.dropdown>.text {
        //width: calc(100% - 20px);

      }

      .ui.dropdown>.dropdown.icon{
        cursor: pointer;
        position: absolute;
        width: auto;
        height: auto;
        line-height: 1.21428571em;
        top: .78571429em;
        right: 1em;
        z-index: 3;
        margin: -.78571429em;
        padding: .91666667em;
        opacity: .8;
        transition: opacity .1s ease;
      }
    }
  }

  &__description {
    margin-bottom: 10px;
  }

  &__inner {
    position: relative;
  }
}

