.key-values {
  width: 400px;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 5px;
  margin-bottom: 40px;

  &__head {
    padding: 5px;
    background-color:  #f9fafb;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    font-weight: bold;
    border-radius: 5px 5px 0 0;

  }
  &__row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 5px;
    &:last-of-type {
      border: none;
    }
    &__column {
      span {
        margin-left: 1rem;
      }
    }
  }
}