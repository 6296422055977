.side-menu {
  position: fixed;
  left: 0;
  width: 175px;
  height: 100vh;
  z-index: 9;
  margin-top: 50px;
  border-right: 1px solid rgba(0,0,0,0.2);
  border-collapse: collapse;
  display: none;
  background-color: white;

  @media only screen and (min-width: 768px) {
    display: block;
  }
  &.open {
    display: block;
  }

  &__item {
    padding: 10px;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    border-top: 1px solid rgba(0,0,0,0.2);
    font-weight: bold;
    cursor: pointer;
    border-collapse: collapse;
    &:first-of-type {
      border-top: none;
    }
    &.no-border-top {
      border-top: none;
    }
    &.active {
      color: #00b5ad;
    }
  }

  &__subitems {
    margin-bottom: 10px;
    &__item {
      padding: 10px 10px 10px 20px;
      
      cursor: pointer;

      &.active {
        font-weight: bold;
        color: #00b5ad;
        text-decoration: underline;
      }
    }
  }

  .bottom {
    position: fixed;
    bottom: 0;
    width: 175px;
    .side-menu__item {
      justify-content: center;
      &:hover {
        background-color: rgba(0,0,0,0.05);
      }
      &:first-of-type {
        border-top: 1px solid rgba(0,0,0,0.2);
        border-bottom: none;
      }
    }
  }
}