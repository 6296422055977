.advanced {
  &__container {
    display: flex;
  }

  &__right {
    padding: 40px;
    box-sizing: border-box;
    margin-left: 0;
    top: 50px;
    position: relative;
    width: 40%;
    height: calc(100vh - 50px);
    overflow: auto;
    display: none;
    @media only screen and (min-width: 1300px) {
      display: block;
    }
  } 

  &__inner {
    padding: 40px;
    box-sizing: border-box;
    margin-left: 0;
    top: 50px;
    position: relative;
    width: 100%;
    height: calc(100vh - 50px);
    overflow: auto;

    @media only screen and (min-width: 768px) {
      width: calc(100% - 175px);
      margin-left: 175px;
    }
    
    &--left {
      @media only screen and (min-width: 1300px) {
        width: 50%;
      }
      .advanced__inner__plot{
        @media only screen and (min-width: 1300px) {
          display: none;
        }
      }
    }

    &__stacked {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
    }

    &__text {
      margin-bottom: 30px;
      width: 1000px;
      max-width: 100%;
    }

    &__plot {
      // display: flex;
      // align-items: center;
      // flex-direction: column;      
      margin-bottom: 40px;
    }
    &__form {
      
      &__section-head {
        font-weight: bold;
        margin-top: 40px;
        margin-bottom: 10px;
        font-size: 0.9rem;
        &--no-top {
          margin-top: 0;
        }
      }
      
      .form-section {
        margin-bottom: 40px;
      }
      .form-field, .calculator-field{
        width: 50%;
        max-width: 400px;
        display: inline-block;
        box-sizing: border-box;
        padding-right: 10px;

        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        .ui.dropdown, .ui.input {
          width: 100%;
          input {
            width: unset;

          }
        }
      }
    }
  }
  .unknown {
    color: red;
  }
}