.project {
  height: calc(100vh - 50px);
  position: relative;
  top: 50px;
  padding: 10px;

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;

    &__text {
    }
    &__form {
      margin: 40px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      // &__item {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: flex-end;
      // }
    
      .form-field, .calculator-field {
        width: 550px;

        label {
          display: inline-block;
          text-align: end;
          vertical-align: center;
          margin-right: 20px;
          width: calc(50% - 20px);
        }
        .ui.input, .ui.dropdown {
          width: 50%;
        }
      }
      &__action {
        margin-top: 20px;
        width: 100%;
        text-align: center;
      }

    }
  }
  
}