.output-table {
  overflow: auto;
  margin-bottom: 40px;
  max-width: 1000px;
  
  &__section-head {
    font-weight: bold;
    text-decoration: underline;
  }
  &__section-summation {
    font-weight: bold;
  }
}