.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  background: white;

  &--centered {
    justify-content: center;
  }

  i.icon.nav-bar__menu {
    display: block;
    margin-right: 20px;
    @media only screen and (min-width: 768px) {
      display: none;
    }
  }

  &__title {
    text-align: center;
    width: 70%;
  }

  &__logo {
    width: 15%;
    a,
    a:hover {
      display: none;
      color: initial;

      @media only screen and (min-width: 768px) {
        display: block;
      }
    }
  }

  &__loader {
    width: 15%;
    right: 10px;
    text-align: right;
    .ui.loader:after {
      border-color: #00b5ad transparent transparent;
      border-width: .3em;
    }
    .ui.loader:before {
      border-width: .3em;
    }
  }

  &__title,
  &__logo,
  &__loader {
    font-size: 1.5rem;
    font-weight: bold;
    @media only screen and (min-width: 768px) {
      width: 33.3%;
    }
  }
}