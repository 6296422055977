.emissions-table {
  margin-bottom: 40px;
  overflow: auto;
  max-width: 1000px;
  
  &__section-head {
    font-weight: bold;
    background-color:  #f9fafb;
    &:first-of-type {
      border-radius: 5px 5px 0;
    }
  }
}